<template>
  <div id="cart" style="padding-top: 20px; padding-bottom: 20px;">
    <LoadCircle :isVisible="!hasLoadedCartItems" />
    <div class="cart-container" v-if="hasLoadedCartItems">
      <UploadItem
        :class="$style.upload"
        v-for="(uploadItem, index) in uploadItems"
        :key="index"
        :uploadItem="uploadItem"
        @upload="uploadFile"
      />
      <br />
      <Button
        buttonText="決済へ進む"
        role="link"
        @click="saveFiles"
        v-if="!hasEmptyUploadItem"
      />
      <span v-else>
        <n-alert
          style="margin:0 48px 0 48px;"
          title="合成対象の写真をアップロードしてください"
          type="warning"
        ></n-alert>
        <br />
        <Button buttonText="決済へ進む" disabled />
        <div id="error-message"></div>
      </span>
      <br />
      <div style="text-align:center; padding:0 28px 0 24px;">
        <!-- <p :class="$style.product_text">
					合成対象の画像をアップロードしてください
				</p> -->
        <ul :class="$style.product_text">
          <li :class="$style.upload_attention">
            画像のファイルサイズは1MB～100MBの範囲としてください。
          </li>
          <li :class="$style.upload_attention">
            アップロードする画像の枚数は3枚までの範囲としてください。
          </li>
          <li :class="$style.upload_attention">
            アップロードする画像はSTYLE GUIDEを参考にしてください。
          </li>
        </ul>
      </div>
      <StyleGuide />
    </div>
  </div>
</template>

<script>
import axios from "../../../plugins/axios";
import { mapActions, mapGetters } from "vuex";
import Button from "/src/components/ec/ui/Button/Button";
import LoadCircle from "/src/components/ec/common/LoadCircle.vue";
import UploadItem from "/src/components/ec/upload/UploadItem";
import StyleGuide from "/src/pages/ec/styleguide/StyleGuide.vue";

var stripe = window.Stripe(
  "pk_live_51JgrYUJJlS9wK262y5CnVKgLQamCf9aTmhSaZiQmE5g8EzNPJHjVf9nPEfZYzawjgbDWZXCl5ag2gnqE08WnZzwj00XHKJS9X0"
);

export default {
  name: "UploadPage",
  components: {
    UploadItem,
    Button,
    LoadCircle,
    StyleGuide,
  },
  data() {
    return {
      uploadItems: [],
      hasLoadedCartItems: false,
      lineItems: [],
    };
  },
  mounted() {},
  created() {
    this.loadCartItems();
  },
  computed: {
    ...mapGetters("carts", { cartItems: "all" }),
    ...mapGetters("auth", { userId: "userId", idToken: "idToken" }),
    hasEmptyUploadItem() {
      let result = false;
      this.uploadItems.forEach((item) => {
        if (!item.image1 && !item.image2 && !item.image3) {
          result = true;
        }
      });
      return result;
    },
  },
  methods: {
    ...mapActions("carts", {
      loadCarts: "set",
    }),
    async loadCartItems() {
      await this.loadCarts({ userId: this.userId });
      if (!this.cartItems.length) this.$router.push({ name: "Cart" });
      this.cartItems.forEach((item) => {
        if (this.getUploadItemIndex(item.id) == -1) {
          this.uploadItems.push({
            cartItem: item,
            image1: null,
            image2: null,
            image3: null,
            image1URL: "",
            image2URL: "",
            image3URL: "",
          });
        }
      });
      this.setLineItems();
    },
    setLineItems() {
      this.cartItems.forEach((item) => {
        let isExist = false;
        this.lineItems.forEach((ii) => {
          if (item.product.color.stripe_key == ii.price) isExist = true;
        });
        if (!isExist)
          this.lineItems.push({
            price: item.product.color.stripe_key,
            quantity: 1,
          });
      });
      this.hasLoadedCartItems = true;
    },
    getUploadItemIndex(cartItemId) {
      let result = -1;
      this.uploadItems.forEach((item, index) => {
        if (item.cartItem && item.cartItem.id == cartItemId) {
          result = index;
        }
      });
      return result;
    },
    async uploadFile(cartItemId, index, file) {
      this.uploadItems[this.getUploadItemIndex(cartItemId)][
        `image${index}`
      ] = await file;
      this.uploadItems[this.getUploadItemIndex(cartItemId)][
        `image${index}URL`
      ] = await URL.createObjectURL(file);
    },
    async saveFiles() {
      let config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let items = this.uploadItems;
      await Promise.all(
        items.map(async (item) => {
          for (let i = 1; i <= 3; i++) {
            if (!item[`image${i}`]) continue;

            let signedUrl = await this.getPresignedUrl(item, i);
            await axios
              .put(signedUrl, item[`image${i}`], config)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
      );
      await this.Checkout();
    },
    async Checkout() {
      const lineItems = await this.lineItems;
      await stripe
        .redirectToCheckout({
          lineItems: lineItems,
          mode: "payment",
          successUrl: "https://x-couture.tokyo/checkout/success",
          cancelUrl: "https://x-couture.tokyo/checkout/cancel",
        })
        .then(function(result) {
          if (result.error) {
            var displayError = document.getElementById("error-message");
            displayError.textContent = result.error.message;
          }
        });
    },
    async getPresignedUrl(uploadItem, index) {
      function getNowYMD() {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth() + 1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + m + d;
        return result;
      }
      function getHHMISS() {
        var dt = new Date();
        var hh = dt.getHours();
        var mi = dt.getMinutes();
        var ss = dt.getSeconds();
        hh = ("00" + hh).slice(-2);
        mi = ("00" + mi).slice(-2);
        ss = ("00" + ss).slice(-2);
        return hh + mi + ss;
      }
      const date = getNowYMD();
      const time = getHHMISS();
      const filename =
        time +
        `_cart_${uploadItem.cartItem.id}_${uploadItem[`image${index}`].name}`;
      let url = "";
      await axios
        .get("/customers/s3?date=" + date + "&filename=" + filename)
        .then((res) => {
          console.log(res);
          url = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      return url;
    },
  },
};
</script>

<style module lang="scss" scoped>
#cart {
  .upload {
    width: 3rem;
    height: 3rem;
    margin-left: 1.5rem;
  }
  .upload_attention {
    line-height: 1rem;
    list-style-type: disc;
    margin-right: 2rem;
    font-size: 0.8rem;
  }
}
</style>
