<template>
  <div :class="$style.second_wrap">
    <div :class="$style.second_color">
      <div :class="$style.second_text">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondaryButton",
  props: {
    buttonText: { type: String, default: "Button" },
  },
};
</script>

<style lang="scss" module>
@import "../../../../stylesheet";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100&display=swap");
.second_wrap {
  position: relative;
  z-index: 0;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.6;
  }
  cursor: pointer;
}

.second_color {
  --border-width: 2px;
  --border-radius: 40px;
  background: #fff;
  border-radius: 40px;
  box-sizing: border-box;
  margin: auto;
  padding: calc(24px - var(--border-width)) 24px;
  position: relative;
  width: calc(10rem - 8px);
  height: calc(3rem - 2px);
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 1px;
  @include mobile-link-english-small;
}

.second_color:after {
  background: linear-gradient(
    108deg,
    $medium-slate-blue 0%,
    $medium-turquoise 100%
  );
  border-radius: 40px;
  bottom: 0;
  content: "";
  left: 0;
  margin: calc(var(--border-width) * -1);
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.second_text {
  background: linear-gradient(
    108deg,
    $medium-slate-blue 0%,
    $medium-turquoise 100%
  );
  -webkit-background-clip: text;
  font-size: 10.5px;
  color: transparent;
}
</style>
