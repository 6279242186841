<template>
  <n-badge :value="quantities" :max="15">
    <img
      alt=""
      class="cart"
      src="https://static.overlay-tech.com/assets/fd4a39d7-fd8b-4900-a7a9-b3ccd83a7ba4.svg"
    />
  </n-badge>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "V-Cart",
  computed: {
    quantities() {
      return this.$store.getters["carts/all"].length;
    },
  },
});
</script>
