<template>
	<div :class="$style.fullScreenLoadCircle" v-if="isVisible">
		<div :class="$style.loadCircleContainer">
			<LoadCircle :isVisible="isVisible" />
		</div>
	</div>
</template>

<script>
import LoadCircle from "./LoadCircle.vue";
export default {
	name: "FullScreenLoadCircle",
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		LoadCircle,
	},
};
</script>

<style lang="scss" module>
.fullScreenLoadCircle {
	position: fixed;
	top: 0;
	width: 100vw;
	max-width: 414px;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	.loadCircleContainer {
		width: 100px;
		height: 100px;
		background-color: rgba(255, 255, 255, 0.8);
		border-radius: 10px;
	}
}
</style>
