<template>
  <div id="home">
    <div :class="$style.bg_gray_50">
      <InstagramFeed />
      <div>
        <!-- <div :class="[$style.w_two_third, $style.h_14, $style.bg_gray_800]">
					<p :class="$style.how_to_title">
						How to Use
					</p>
				</div>
				<UseFlow
					id="how-to-use"
					title="友達にシェアしたり、SNSで投稿してみよう！"
				/>
				<UseFlow title="お気に入りの自分をSNSのアイコンにしてみよう！" />
				<UseFlow
					id="how-to-buy"
					title="デジタルドレスを着た様々な自分を集めてみよう！"
				/> -->
        <!-- <div
					:class="[
						$style.w_two_third,
						$style.h_14,
						$style.bg_gray_800,
						$style.right_justify,
					]"
				>
					<p :class="$style.how_to_title">
						How to Buy
					</p>
				</div> -->
      </div>
      <br />
      <video
        :class="$style.video"
        src="https://x-couture-dev-products.s3.ap-northeast-1.amazonaws.com/videos/how-to-buy.mp4"
        controls
        muted
        playsinline
        loop
        autoplay
      ></video>
    </div>
  </div>
</template>

<script>
import InstagramFeed from "/src/components/ec/ui/InstagramFeed";
// import UseFlow from "/src/components/ec/ui/UseFlow/UseFlow";

export default {
  name: "HomePage",
  components: {
    InstagramFeed,
    // UseFlow,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style module>
.bg_gray_50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.w_two_third {
  width: 60%;
}
.h_14 {
  height: 3.5rem;
}
.bg_gray_800 {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity));
  display: flex;
  align-items: center;
  justify-content: center;
}
.how_to_title {
  align-items: center;
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.right_justify {
  margin-left: 40%;
}
.video {
  max-width: 414px;
  width: 100%;
}
</style>
