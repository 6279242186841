<template>
  <div style="padding: 24px">
    <n-form :model="model" ref="formRef" :rules="rules">
      <n-form-item path="email" label="Email" :size="large">
        <n-input
          v-model:value="model.email"
          @keydown.enter.prevent
          placeholder="メールアドレス"
        />
      </n-form-item>
      <n-form-item path="password" label="Password" :size="large">
        <n-input
          v-model:value="model.password"
          placeholder="パスワード"
          @input="handlePasswordInput"
          type="password"
          @keydown.enter.prevent
        />
      </n-form-item>
      <span v-if="isSuccessed === false">
        <br />
        <n-alert title="ログインに失敗しました" type="error"></n-alert>
        <br />
      </span>
      <div style="display: flex; justify-content: flex-end; margin-top: 1rem">
        <Button
          buttonText="ログイン"
          :disabled="!(this.model.email && this.model.password)"
          @click.prevent="signIn()"
        />
      </div>
      <div style="color: #b7b7b7; padding: 1rem 0 0 0">または</div>
      <br />
      <div style="display: flex; justify-content: flex-end">
        <GuestSignin />
      </div>
      <div style="color: #b7b7b7; padding: 1rem 0 0 0">
        会員情報を登録することで、次回からのお買い物時にお名前やメールアドレスをご入力しなくてもスムーズにご注文いただけます。
      </div>
      <br />
      <br />
      <div>初めてご利用の方</div>
      <div style="display: flex; justify-content: flex-end">
        <Button buttonText="新規会員登録" @click.prevent="toRegister()" />
      </div>
      <!-- <div style="display: flex; justify-content: flex-end;">
				<n-button @click.prevent="googleSignIn()" round type="primary"
					>Googleログイン</n-button
				>
			</div> -->
    </n-form>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import axios from "axios";
import Button from "../../../components/ec/ui/Button/Button.vue";
import GuestSignin from "../../../components/ec/auth/GuestSignin.vue";

export default defineComponent({
  name: "V-Signin",
  components: {
    Button,
    GuestSignin,
  },
  data() {
    return {
      isSuccessed: true,
    };
  },
  setup() {
    const active = ref(false);
    const placement = ref("right");
    const activate = (place) => {
      active.value = true;
      placement.value = place;
    };
    const formRef = ref(null);
    const modelRef = ref({
      email: null,
      password: null,
    });
    async function signIn() {
      this.isSuccessed = await this.$store.dispatch("auth/signInAction", {
        email: this.model.email,
        password: this.model.password,
      });
      if (this.isSuccessed == true) {
        this.$root.isSignedIn = true;
        if (this.$route.query.productColorId) {
          await this.getUserInfo();
          this.$store
            .dispatch("carts/add", {
              params: { product_color_id: this.$route.query.productColorId },
              userId: this.$store.getters["auth/userId"],
            })
            .then(() => {
              this.$router.push("/cart");
            });
        } else {
          this.$router.push("/");
        }
      }
      this.getUserInfo();
      this.model.email = "";
      this.model.password = "";
    }
    async function toRegister() {
      this.$router.replace({
        path: "/register",
        query: {
          productColorId: this.$route.query.productColorId,
        },
      });
    }
    // async function googleSignIn() {
    // 	let data = await this.$store.dispatch("auth/googleSignInAction");
    // 	if (data != false) {
    // 		await this.postDB(data);
    // 	}
    // 	this.getUserInfo();
    // }
    async function postDB(data) {
      const param = {
        email: data,
      };
      await axios
        .post(process.env.VUE_APP_API_URL + "/customers/user", param)
        .then((response) => {
          console.log(response);
          this.$router.replace(this.$route.query.redirect || "/");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function getUserInfo() {
      await this.$store.dispatch("auth/getUserInfo");
    }
    return {
      active,
      placement,
      activate,
      formRef,
      signIn,
      postDB,
      getUserInfo,
      toRegister,
      // googleSignIn,
      model: modelRef,
      rules: {
        email: [
          {
            required: true,
            validator(rule, value) {
              if (!value) {
                return new Error("メールアドレスを入力してください。");
              } else if (/.+@.+/.test(value) != true) {
                return new Error("メールアドレスが無効な形式です。");
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "パスワードを入力してください。",
          },
          {
            required: true,
            validator(rule, value) {
              if (!value) {
                return new Error("パスワードを入力してください。");
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
      },
    };
  },
});
</script>
<style></style>
