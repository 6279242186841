<template>
	<div :class="$style.loadCircle" v-if="isVisible">
		<n-spin size="large" />
	</div>
</template>

<script>
export default {
	name: "LoadCircle",
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" module>
.loadCircle {
	width: auto;
	text-align: center;
	line-height: 100px;
	font-size: 24px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
</style>
