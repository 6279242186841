<template>
	<div style="text-align:center">
		<h2 style="margin-top:1rem; margin-bottom:1rem;">会員登録が完了しました</h2>
		<p>
			ご登録いただき、誠にありがとうございます。
		</p>
		<p>
			引き続きお買い物をお楽しみください。
		</p>
		<Button
			@click="goShop"
			buttonText="お買い物を始める"
			style="margin-bottom:1rem; margin-top:1rem;"
		/>
	</div>
</template>

<script>
import Button from "../../../components/ec/ui/Button/Button.vue";
export default {
	components: {
		Button,
	},
	methods: {
		goShop() {
			this.$router.replace("/products");
		},
	},
};
</script>

<style lang="scss" scoped></style>
