<template>
  <div id="ec-root">
    <n-loading-bar-provider>
      <n-message-provider>
        <n-notification-provider>
          <n-dialog-provider>
            <Header />
            <router-view />
            <Footer />
          </n-dialog-provider>
        </n-notification-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Header from "/src/components/ec/common/Header";
import Footer from "/src/components/ec/common/Footer";

export default {
  name: "RootPage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  created() {
    this.$root.isSignedIn = !!localStorage.getItem("userId");
    this.load();
  },
  computed: {
    ...mapGetters("auth", { userId: "userId" }),
  },
  methods: {
    ...mapActions("auth", { getUserInfo: "getUserInfo" }),
    ...mapActions("carts", { loadCartItems: "set" }),
    async load() {
      await this.getUserInfo();
      if (this.$root.isSignedIn)
        await this.loadCartItems({ userId: this.userId });
    },
  },
  mounted() {
    this.$store.dispatch("auth/getUserInfo");
  },
};
</script>

<style scoped></style>
