<template>
  <Register />
</template>

<script>
import Register from "../../../components/ec/auth/Register.vue";
export default {
  name: "RegisterPage",
  components: {
    Register,
  },
  data() {
    return {};
  },
};
</script>

<style module></style>
