<template>
	<div :class="$style.container">
		<img
			:class="$style.img"
			:src="cartItem.product.color.images[0].url"
			@click="$emit('show')"
		/>
		<div :class="$style.margin" @click="$emit('show')">
			<p :class="$style.text">
				{{ cartItem.product.color.name }}
			</p>
			<p :class="$style.text">
				カラー：{{ cartItem.product.color.color_id }}
			</p>
			<p :class="$style.text">
				￥ {{ cartItem.product.color.cost }} 税込
			</p>
		</div>
		<div :class="$style.margin">
			<div :class="$style.deleteCartButton" @click="$emit('delete')">削除</div>
		</div>
	</div>
</template>

<script>
// import Stepper from "/src/components/ec/ui/Stepper";
export default {
	props: {
		cartItem: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		// Stepper,
	},
	computed: {
	},
};
</script>

<style lang="scss" module>
@import "../../../stylesheet";
.container {
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
}
.img {
	margin: 1rem auto 1rem auto;
	width: 100px;
	height: 120px;
}
.margin {
	margin: 1rem auto 1rem auto;
}
.text {
	font-size: 0.75rem;
	line-height: 1rem;
	letter-spacing: 0.05em;
	--tw-text-opacity: 1;
	color: rgba(55, 65, 81, var(--tw-text-opacity));
	margin-bottom: 0.25rem;
}
.image {
	font-size: 0.75rem;
	letter-spacing: 0.05em;
	line-height: 0.75rem;
	--tw-text-opacity: 1;
	color: rgba(55, 65, 81, var(--tw-text-opacity));
	margin-bottom: 0.25rem;
}
</style>
