<template>
  <button :class="$style.color" ontouchstart="">
    <div :class="$style.name">{{ buttonText }}</div>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    buttonText: { type: String, default: "Button" },
  },
};
</script>

<style lang="scss" module>
@import "../../../../stylesheet";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100&display=swap");
.color {
  background: linear-gradient(
    108deg,
    $medium-slate-blue 0%,
    $medium-turquoise 100%
  );
  border-radius: 40px;
  margin: auto;
  display: flex;
  width: 10rem;
  height: 3rem;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.5;
  }
  border: none;
  &:disabled {
    opacity: 0.6;
  }
}
.name {
  color: $grayscale-background;
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 1px;
  @include mobile-link-english-small;
}
</style>
