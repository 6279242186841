import api from '../../api';
import mixins from '../mixins';
const state = {
  ...mixins.state,
};

const getters = {
  ...mixins.getters,
};

const actions = {
  async set({commit}, {userId}) {
    const res = await api.carts.index({userId});
    commit('SET', {array: res.data.carts});
    return res.data.carts;
  },
  async add({commit}, {params, userId}) {
    const res = await api.carts.create({params, userId});
    commit('SET', {array: res.data.carts});
    return res.data.carts;
  },
  async delete({commit}, {userId, cartId}) {
    const res = await api.carts.delete({userId, cartId});
    commit('SET', {array: res.data.carts});
    return res.data.carts;
  },
};

const mutations = {
  ...mixins.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
