import { createStore } from 'vuex'
import auth from './modules/auth.js'
import products from './modules/products.js';
import carts from './modules/carts.js';
import categories from './modules/categories.js';
import colors from './modules/colors.js';
import constants from './modules/constants.js';

const store = createStore({
  modules: {
    auth,
    products,
    carts,
    categories,
    colors,
    constants,
  }
});

export default store;
