const state = {
  all: [],
}

const getters = {
  all: state => state.all,
  findBy: state => cond => state.all.find(el => Object.keys(cond).every(key => el[key] == cond[key])),
  filterBy: state => cond => state.all.filter(el => Object.keys(cond).every(key => el[key] == cond[key])),
}

const mutations = {
  SET(state, {array, source}) {
    source = source || 'all';
    state[source] = array;
  },
  ADD(state, {array, element, source}) {
    source = source || 'all';
    if (element) {
      state[source].push(element);
    }
    if (array) {
      state[source] = [...state[source], ...array];
    }
  },
  DELETE_ALL(state, {source}) {
    source = source || 'all';
    state[source] = [];
  }
}

export default {
  state,
  getters,
  mutations,
}
