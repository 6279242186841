<template>
  <div style="padding: 24px">
    <h2 style="text-align: center">新規会員登録</h2>
    <n-form :model="model" ref="formRef" :rules="rules">
      <n-grid :span="12" :x-gap="30">
        <n-form-item-gi :span="6" path="lastName" label="お名前">
          <n-input
            v-model:value="model.lastName"
            @keydown.enter.prevent
            placeholder="例）山田"
          />
        </n-form-item-gi>
        <n-form-item-gi :span="6" path="firstName">
          <n-input
            v-model:value="model.firstName"
            @keydown.enter.prevent
            placeholder="太郎"
          />
        </n-form-item-gi>
      </n-grid>
      <br />
      <n-grid :span="12" :x-gap="30">
        <br />
        <n-form-item-gi :span="6" path="lastNameKana" label="お名前（カナ）">
          <n-input
            v-model:value="model.lastNameKana"
            @keydown.enter.prevent
            placeholder="例）ヤマダ"
          />
        </n-form-item-gi>
        <n-form-item-gi :span="6" path="firstNameKana">
          <n-input
            v-model:value="model.firstNameKana"
            @keydown.enter.prevent
            placeholder="タロウ"
          />
        </n-form-item-gi>
      </n-grid>
      <br />
      <n-form-item first path="gender" label="性別" ref="">
        <n-radio-group v-model:value="model.gender" name="gender">
          <n-radio
            :checked="checkedValue === 'man'"
            value="0"
            name="basic-demo"
          >
            男性
          </n-radio>
          <n-radio
            :checked="checkedValue === 'woman'"
            value="1"
            name="basic-demo"
          >
            女性
          </n-radio>
          <n-radio
            :checked="checkedValue === 'other'"
            value="2"
            name="basic-demo"
          >
            その他
          </n-radio>
        </n-radio-group>
      </n-form-item>
      <n-form-item path="instagramId" label="Instagram ID">
        <n-input
          v-model:value="model.instagramId"
          @keydown.enter.prevent
          placeholder="例）x_couture.official"
        />
      </n-form-item>
      <br />
      <n-form-item path="email" label="メールアドレス（ログインID）">
        <n-input
          v-model:value="model.email"
          type="email"
          @keydown.enter.prevent
          placeholder="例）x-couture@x-couture.tokyo"
        />
      </n-form-item>
      <br />
      <n-form-item path="password" label="パスワード">
        <n-input
          v-model:value="model.password"
          show-password-on="mousedown"
          type="password"
          @keydown.enter.prevent
          placeholder="例）x1234couture"
        />
      </n-form-item>
      <br />
      <n-form-item
        first
        path="reenteredPassword"
        label="パスワード再入力"
        ref="rPasswordFormItemRef"
      >
        <n-input
          :disabled="!model.password"
          v-model:value="model.reenteredPassword"
          type="password"
          @keydown.enter.prevent
        />
      </n-form-item>
      <span v-if="isSuccessed === false">
        <br />
        <n-alert title="登録に失敗しました" type="error"></n-alert>
        <br />
      </span>
      <div style="display: flex; justify-content: flex-end; margin-top: 1rem">
        <Button
          @click.prevent="signUp()"
          :disabled="isDisabled"
          buttonText="同意して会員登録する"
        />
      </div>
    </n-form>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useMessage } from "naive-ui";
import axios from "../../../plugins/axios";
import Button from "../../../components/ec/ui/Button/Button.vue";

export default defineComponent({
  name: "V-Register",
  components: {
    Button,
  },
  data() {
    return {
      isSuccessed: true,
    };
  },
  computed: {
    isDisabled() {
      return !(
        this.model.firstName &&
        this.model.lastName &&
        /^[ァ-ンヴー]*$/.test(this.model.firstNameKana) &&
        /^[ァ-ンヴー]*$/.test(this.model.lastNameKana) &&
        /.+@.+/.test(this.model.email) &&
        /.{8,}$/.test(this.model.password) &&
        /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/.test(this.model.password)
      );
    },
  },
  setup() {
    const active = ref(false);
    const placement = ref("right");
    const activate = (place) => {
      active.value = true;
      placement.value = place;
    };
    const checkedValueRef = ref(null);
    const formRef = ref(null);
    const rPasswordFormItemRef = ref(null);
    const message = useMessage();
    const modelRef = ref({
      firstName: null,
      lastName: null,
      firstNameKana: null,
      lastNameKana: null,
      email: null,
      password: null,
      reenteredPassword: null,
      instagramId: null,
      gender: "0",
    });
    async function signUp() {
      this.isSuccessed = await this.$store.dispatch("auth/signUpAction", {
        email: this.model.email,
        password: this.model.password,
      });
      if (this.isSuccessed == true) {
        await this.postDB();
        await this.getUserInfo();
      }
      // this.model.firstName = "";
      // this.model.lastName = "";
      // this.model.firstNameKana = "";
      // this.model.lastNameKana = "";
      // this.model.gender = "";
      // this.model.email = "";
      // this.model.password = "";
      // this.model.instagramId = "";
    }
    async function postDB() {
      const data = {
        first_name: this.model.firstName,
        last_name: this.model.lastName,
        first_name_kana: this.model.firstNameKana,
        last_name_kana: this.model.lastNameKana,
        email: this.model.email,
        gender: this.model.gender,
        instagram_id: this.model.instagramId,
      };
      console.log(data);
      await axios
        .post("/customers/user", data)
        .then(async () => {
          this.$root.isSignedIn = true;
          if (this.$route.query.productColorId) {
            await this.getUserInfo();
            this.$store
              .dispatch("carts/add", {
                params: { product_color_id: this.$route.query.productColorId },
                userId: this.$store.getters["auth/userId"],
              })
              .then(() => {
                this.$router.push("/cart");
              });
          } else {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function getUserInfo() {
      await this.$store.dispatch("auth/getUserInfo");
    }
    function validatePasswordStartWith(rule, value) {
      return (
        modelRef.value.password &&
        modelRef.value.password.startsWith(value) &&
        modelRef.value.password.length >= value.length
      );
    }
    function validatePasswordSame(rule, value) {
      return value === modelRef.value.password;
    }
    return {
      active,
      placement,
      activate,
      formRef,
      rPasswordFormItemRef,
      signUp,
      postDB,
      getUserInfo,
      model: modelRef,
      rules: {
        lastName: [
          {
            required: true,
            validator(rule, value) {
              if (!value) {
                return new Error("「苗字」を入力してください。");
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
        firstName: [
          {
            required: true,
            validator(rule, value) {
              if (!value) {
                return new Error("「名前」を入力してください。");
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
        lastNameKana: [
          {
            required: true,
            validator(rule, value) {
              if (!value) {
                return new Error("「ミョウジ」を入力してください。");
              } else if (/^[ァ-ンヴー]*$/.test(value) != true) {
                return new Error("全角カタカナで入力してください。");
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
        firstNameKana: [
          {
            required: true,
            validator(rule, value) {
              if (!value) {
                return new Error("「ナマエ」を入力してください。");
              } else if (/^[ァ-ンヴー]*$/.test(value) != true) {
                return new Error("全角カタカナで入力してください。");
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
        email: [
          {
            required: true,
            validator(rule, value) {
              if (!value) {
                return new Error("メールアドレスを入力してください。");
              } else if (/.+@.+/.test(value) != true) {
                return new Error("メールアドレスが無効な形式です。");
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "パスワードを入力してください。",
          },
          {
            required: true,
            validator(rule, value) {
              if (/.{8,}$/.test(value) != true) {
                return new Error("パスワードは8文字以上で入力してください。");
              } else if (
                /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/.test(value) != true
              ) {
                return new Error(
                  "パスワードは英字と数字を混ぜて入力してください。"
                );
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
        reenteredPassword: [
          {
            required: true,
            message: "再入力パスワードを入力してください",
            trigger: ["input", "blur"],
          },
          {
            validator: validatePasswordStartWith,
            message: "パスワードが再入力したパスワードと異なっています",
            trigger: "input",
          },
          {
            validator: validatePasswordSame,
            message: "パスワードが再入力したパスワードと異なっています",
            trigger: ["blur", "password-input"],
          },
        ],
        gender: [
          {
            required: true,
            message: "性別を選択してください。",
          },
        ],
        instagramId: [
          {
            validator(rule, value) {
              if (/^[0-9a-zA-Z]*$/.test(value) != true) {
                return new Error("入力されたIDは無効な形式です。");
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
      },
      disabled: ref(true),
      checkedValue: checkedValueRef,
      // handleChange(e) {
      // 	checkedValueRef.value = e.target.value;
      // },
      handleValidateButtonClick(e) {
        e.preventDefault();
        formRef.value.validate((errors) => {
          if (!errors) {
            message.success("会員登録成功");
            this.signUp();
            // this.postDB();
          } else {
            console.log(errors);
            message.error("会員登録失敗");
          }
        });
      },
    };
  },
});
</script>
<style>
.carousel-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
</style>
