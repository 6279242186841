<template>
  <div style="text-align:center">
    <h2 style="margin-top:1rem; margin-bottom:1rem;">ログアウトしました</h2>
    <p>
      引き続きお買い物をお楽しみください。
    </p>
    <Button
      @click="home()"
      buttonText="ホームに戻る"
      style="margin-bottom:1rem; margin-top:1rem;"
    />
  </div>
</template>

<script>
import Button from "../../../components/ec/ui/Button/Button.vue";
export default {
  name: "LogoutPage",
  components: {
    Button,
  },
  created() {
    this.$store.dispatch("auth/signOutAction");
  },
  methods: {
    home() {
      window.location.href = "/";
      // this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
