import api from '../../api';
import mixins from '../mixins';
const state = {
  ...mixins.state,
};

const getters = {
  ...mixins.getters,
};

const actions = {
  async set({commit}, {userId}) {
    const res = await api.products.index({userId: userId});
    commit('SET', {array: res.data.products});
  },
};

const mutations = {
  ...mixins.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
