<template>
	<div :class="$style.card">
		<img :src="styleImage" alt="" :class="$style.img" />
		<div :class="$style.icon" :style="iconColor">
			<img :src="vector" alt="" :class="$style.vector" />
		</div>
		<p :class="$style.description">{{ description }}</p>
	</div>
</template>

<script>
export default {
	name: "StyleGuideCard",
	props: {
		description: {
			type: String,
			default: "光が自然であること",
		},
		styleImage: {
			type: String,
			default:
				"https://static.overlay-tech.com/assets/0068fbd8-d978-4d37-8fc6-3a9f923323af.png",
		},
		vector: {
			type: String,
			default:
				"https://static.overlay-tech.com/assets/0b679309-c77c-4f5a-a126-22e86fdf2960.svg",
		},
		iconColor: {
			type: String,
			default: "background-color: rgba(0, 186, 136, 1);",
		},
	},
};
</script>

<style lang="scss" module>
@import "../../../stylesheet";

.card {
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.img {
	width: 155px;
	height: 193px;
	margin-bottom: 10px;
}
.icon {
	// background-color: $success-default;
	// width: 16px;
	// height: 16px;
	border-radius: 50%;
	padding: 9px 8px 9px 7px;
	display: flex;
	align-items: center;
}
.vector {
	flex: 1;
	align-self: stretch;
	object-fit: cover;
}
.description {
	font-size: 0.7rem;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	color: $black;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.75px;
	// @include mobile-text-japanese-x-small;
}
</style>
