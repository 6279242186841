<template>
  <div style="text-align:center;">
    <h1>決済キャンセル</h1>
    <div>もう一度やり直してください</div>
    <Button
      @click="goCart"
      buttonText="カートへ戻る"
      style="margin-bottom:1rem; margin-top:1rem;"
    />
  </div>
</template>

<script>
import Button from "../../../components/ec/ui/Button/Button.vue";
export default {
  name: "CancelPage",
  components: {
    Button,
  },
  methods: {
    goCart() {
      this.$router.replace("/cart");
    },
  },
};
</script>

<style lang="scss" scoped></style>
