<template>
	<div :class="$style.container">
		<p :class="$style.sum">
			商品合計
		</p>
		<p :class="$style.tax">税込</p>
		<p :class="$style.price">
			￥ {{ sum }}
		</p>
	</div>
</template>

<script>
export default {
	name: "CartSum",
	props: {
		sum: {
			type: Number,
			default: 0,
		}
	},
	components: {},
};
</script>

<style lang="scss" module>
@import "../../../stylesheet";
.container {
	display: flex;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	align-items: center;
	justify-content: center;
}
.sum {
	margin-right: 1rem;
	display: flex;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 700;
}
.tax {
	font-size: 0.75rem;
	line-height: 1rem;
	margin-right: 5rem;
}
.price {
	font-size: 1.125rem;
	line-height: 1.75rem;
	text-align: right;
	font-weight: 700;
}
</style>
