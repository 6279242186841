<template>
  <div :class="$style.font">
    <div :class="$style.carouselContainer">
      <div :class="$style.slides">
        <div
          v-for="image in selectedProductColor.images"
          :key="image.id"
          :class="$style.carousel__slide"
        >
          <img
            :class="$style.main_img"
            :src="image.url"
            preview-disabled="true"
          />
        </div>
      </div>
    </div>
    <p :class="[$style.product_text, $style.name]">
      {{ selectedProductColor.name }}
    </p>
    <p :class="[$style.product_text, $style.price]">
      <!-- ¥{{ selectedProductColor.cost }}（税込） -->
      ¥{{ selectedProductColor.cost }}
    </p>
    <hr :class="$style.bar" />
    <div :class="$style.colorInfo">
      <p :class="$style.info_title">カラー</p>
      <div
        :class="$style.color"
        v-for="color in product.colors"
        :key="color.id"
        :style="'background-color:#' + color.code + ';'"
        @click="onClickColor(color)"
      />
    </div>
    <hr :class="$style.bar" />
    <Button
      buttonText="カートに入れる"
      @click="$emit('cart', selectedProductColor.id)"
      v-if="!hasProductInCart"
    />
    <Button buttonText="既にカートにあります" v-else disabled />
    <hr :class="$style.bar" />
    <p :class="[$style.info_title, $style.flex]">アイテム説明</p>
    <div
      :class="[$style.product_text, $style.description]"
      v-html="product.description"
    ></div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import Button from "../../ec/ui/Button/Button";
export default {
  name: "ProductView",
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentColor: this.product.colors
        .map((c) => c.color_id)
        .includes(this.$route.query.color)
        ? this.$route.query.color
        : this.product.colors[0].color_id,
    };
  },
  watch: {
    currentColor: {
      deep: true,
      immediate: true,
      handler() {
        this.$router.replace({
          name: "ProductShow",
          params: { productId: this.product.id },
          query: { color: this.currentColor },
        });
      },
    },
  },
  components: {
    Button,
  },
  mounted() {},
  created() {},
  computed: {
    hasProductInCart() {
      return this.selectedProductColor.cart_flag;
    },
    selectedProductColor() {
      let productColor = {};
      this.product.colors.forEach((color) => {
        if (color.color_id == this.currentColor) {
          productColor = color;
        }
      });
      console.log(productColor);
      return productColor;
    },
  },
  methods: {
    isSelectedColor(colorId) {
      return colorId == this.selectedProductColor.id;
    },
    onClickColor(color) {
      this.currentColor = color.color_id;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../stylesheet";
.carousel__prev {
  transform: none;
  left: 30px;
  width: 50px;
  height: 50px;
  background: linear-gradient(
    108deg,
    $medium-slate-blue 0%,
    $medium-turquoise 100%
  );
}
.carousel__next {
  transform: none;
  right: 30px;
  width: 50px;
  height: 50px;
  background: linear-gradient(
    108deg,
    $medium-slate-blue 0%,
    $medium-turquoise 100%
  );
}
//
</style>
//
<style lang="scss" module>
.font {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-style: normal;
}
.carouselContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  .carousel__prev {
    left: 20px;
    box-sizing: content-box;
    border: 5px solid white;
  }
  .carousel__next {
    right: 20px;
    box-sizing: content-box;
    border: 5px solid white;
  }
}
.imageCarousel {
  max-width: 400px;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.imageContainer {
  width: 250px;
  height: 300px;
}
.main_img {
  display: inline-flex;
  flex-direction: column;
  --tw-space-y-reverse: 0;
  align-items: center;
  justify-content: flex-end;
  max-width: 30vw;
  max-height: 300px;
  max-width: 250px;
}
.product_text {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.05em;
  // text-align: center;
  // margin-left: auto;
  // margin-right: auto;
  margin-bottom: 0.5rem;
}
.name {
  font-weight: 500;
  font-size: 1rem;
}
.price {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}
.colorInfo {
  display: flex;
  align-items: flex-start;
  margin: 0 1.5rem;
}
.flex {
  display: flex;
  align-items: center;
  width: auto;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.info_title {
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.05em;
  line-height: 2;
}
.color {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}
.purple {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.blue {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.pink {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.orange {
  --tw-bg-opacity: 1;
  background-color: #ef4444;
}
.gray {
  --tw-bg-opacity: 1;
  background-color: #9ca3af;
}
.stepper {
  margin-left: 5rem;
}
.description {
  width: 90%;
  font-size: 0.8rem;
  text-align: initial;
  padding-left: 1.5rem;
}
.bar {
  display: block;
  width: 90%;
  height: 2px;
  opacity: 0.1;
  background-color: #000000;
  border: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.slides {
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;

  // scroll-snap-points-x: repeat(300px);
  // scroll-snap-type: mandatory;
}
.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  height: 300px;
  margin-right: 50px;
  border-radius: 10px;
  background: white;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}
.slides > div:target {
  // transform: scale(0.8);
}
.slider2 > a {
  display: none;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}
.slider2 > a:active {
  top: 1px;
}
.slider2 > a:focus {
  background: darkgray;
}

/* Don't need button navigation */
@supports (scroll-snap-type) {
  .slider2 > a {
    display: none;
  }
}
</style>
