<template>
  <div id="product-index">
    <div :class="$style.bg_gray_50">
      <p :class="$style.product_title">
        商品一覧
      </p>
      <!-- <div :class="$style.filter">
				<ButtonWithIcon buttonText="絞り込み" />
				<ButtonWithIcon buttonText="おすすめ順" />
			</div> -->
      <ul :class="$style.product_list">
        <div
          :class="$style.product"
          v-for="product in productColors"
          :key="product.id"
        >
          <ProductCard
            :product="product"
            :color="product.color"
            @click="onClickProductCard(product.id, product.color.color_id)"
          />
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import ProductCard from "/src/components/ec/product/ProductCard";
// import ButtonWithIcon from "/src/components/ec/ui/Button/ButtonWithIcon";
export default {
  name: "IndePage",
  components: {
    ProductCard,
    // ButtonWithIcon,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    products() {
      return this.$store.getters["products/all"];
    },
    productColors() {
      let res = [];
      let ps = JSON.parse(JSON.stringify(this.products));
      ps.forEach((product) => {
        let colors = [];
        product.colors.forEach((color) => {
          let pro = Object.create(product);
          pro.color = color;
          colors.push(pro);
        });
        res = res.concat(colors);
      });
      return res;
    },
  },
  methods: {
    onClickProductCard(productId, colorId) {
      this.$router.push({
        name: "ProductShow",
        params: { productId: productId },
        query: { color: colorId },
      });
    },
  },
};
</script>

<style module>
#product-index {
  position: relative;
  text-align: center;
}
.bg_gray_50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.filter {
  display: flex;
  justify-content: center;
}
.product {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.product_list {
  display: flex;
  flex-wrap: wrap;
  padding: initial;
}
.product_title {
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 2.25rem;
  padding-top: 1rem;
  /* padding-bottom: 1rem; */
  text-align: center;
}
</style>
