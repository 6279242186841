// import api from '../../api';
import mixins from '../mixins';
const state = {
  ...mixins.state,
};

const getters = {
  ...mixins.getters,
};

const actions = {
};

const mutations = {
  ...mixins.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
