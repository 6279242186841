<template>
  <SecondaryButton
    buttonText="ログインせずに続行"
    @click.prevent="enter()"
    style="margin:auto;"
  />
</template>

<script>
import axios from "../../../plugins/axios";
import SecondaryButton from "../../../components/ec/ui/Button/SecondaryButton.vue";
export default {
  data() {
    return {};
  },
  components: {
    SecondaryButton,
  },
  methods: {
    async enter() {
      let chars = "abcdefghijklmnopqrstuvwxyz";
      let rand_str = "";
      for (var i = 0; i < 25; i++) {
        rand_str += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      await localStorage.setItem("idToken", rand_str);
      await this.postDB();
      await this.getUserInfo();
    },
    async postDB() {
      const data = {
        guest_token: localStorage.getItem("idToken"),
      };
      await axios
        .post("/customers/user/guest", data)
        .then(async () => {
          this.$root.isSignedIn = true;
          if (this.$route.query.productColorId) {
            await this.getUserInfo();
            this.$store
              .dispatch("carts/add", {
                params: { product_color_id: this.$route.query.productColorId },
                userId: this.$store.getters["auth/userId"],
              })
              .then(() => {
                this.$router.push("/cart");
              });
          } else {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getUserInfo() {
      await this.$store.dispatch("auth/getUserInfo");
    },
  },
};
</script>

<style module></style>
