<template>
  <div id="login" style="padding:24px; text-align:center;">
    <h2>ログイン</h2>
    <Signin />
    <n-divider />
  </div>
</template>

<script>
import Signin from "../../../components/ec/auth/Signin.vue";
// import GuestSignin from "../../../components/ec/auth/GuestSignin";
export default {
  name: "LoginPage",
  components: {
    Signin,
    // GuestSignin,
  },
  data() {
    return {};
  },
  methods: {
    enter() {
      this.$router.replace(this.$route.query.redirect || "/");
    },
  },
};
</script>

<style module></style>
