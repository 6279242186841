<template>
  <div style="text-align:center">
    <h2 style="margin-top:1rem; margin-bottom:1rem;">
      ページが見つかりませんでした。
    </h2>
    <Button
      @click="home()"
      buttonText="ホームに戻る"
      style="margin-bottom:1rem; margin-top:1rem;"
    />
  </div>
</template>

<script>
import Button from "../../../components/ec/ui/Button/Button.vue";
export default {
  name: "404-page",
  components: {
    Button,
  },
  methods: {
    home() {
      window.location.href = "/";
      // 	// this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
