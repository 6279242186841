import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "x-api-key": process.env.VUE_APP_API_KEY,
  },
  responseType: "json",
});

const joinPath = (...args) => args.join("/");

const customersBasePath = "/customers";

const constantsPath = joinPath(customersBasePath, "constants");
const productsPath = joinPath(customersBasePath, "products");
const cartsPath = (userId) => joinPath(customersBasePath, userId, "cart");
// const ordersPath = joinPath(customersBasePath, 'orders');
const s3Path = joinPath(customersBasePath, "s3");
const userPath = joinPath(customersBasePath, "user");
const guestPath = joinPath(userPath, "guest");

export default {
  constants: {
    index: () => api.get(constantsPath),
  },
  products: {
    index: ({ userId }) =>
      api.get(productsPath, { params: { user_id: userId } }),
    show: ({ productId }) => api.get(joinPath(productsPath, productId)),
  },
  carts: {
    index: ({ userId }) => api.get(cartsPath(userId)),
    create: ({ params, userId }) =>
      api.post(cartsPath(userId), { cart: params }),
    delete: ({ userId, cartId }) =>
      api.delete(joinPath(cartsPath(userId), cartId)),
  },
  s3: {
    index: () => api.get(s3Path),
  },
  user: {
    index: () => api.get(userPath),
    create: (params) => api.post(userPath, params),
  },
  guest: {
    index: () => api.get(guestPath),
    create: (params) => api.post(guestPath, params),
  },
};
