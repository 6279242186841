<template>
  <div id="product-show" style="text-align:center">
    <FullScreenLoadCircle :isVisible="isLoading" />
    <ProductView :product="product" @cart="createCartItem" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductView from "../../../components/ec/product/ProductView";
import FullScreenLoadCircle from "/src/components/ec/common/FullScreenLoadCircle";
export default {
  name: "ProductShow",
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    ProductView,
    FullScreenLoadCircle,
  },
  mounted() {},
  computed: {
    ...mapGetters("products", { productBy: "findBy" }),
    ...mapGetters("auth", { userId: "userId" }),
    productId() {
      return Number(this.$route.params.productId);
    },
    product() {
      return this.productBy({ id: this.productId });
    },
  },
  methods: {
    ...mapActions("carts", { addCartItem: "add" }),
    async createCartItem(productColorId) {
      if (!this.userId)
        return this.$router.push({
          path: "/login",
          query: { productColorId: productColorId },
        });
      this.isLoading = true;
      const params = { product_color_id: productColorId };
      await this.addCartItem({ params: params, userId: this.userId });
      this.isLoading = false;
      this.$router.push("/cart");
    },
  },
};
</script>

<style module></style>
