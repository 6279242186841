import api from '../../api';
import mixins from '../mixins';
const state = {};

const getters = {};

const actions = {
  async set({commit}) {
    await api.constants.index().then(res => {
      commit('colors/SET', {array: res.data.colors}, {root: true});
      commit('categories/SET', {array: res.data.category}, {root: true});
    });
  },
};

const mutations = {
  ...mixins.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
