<template>
  <n-button-group>
    <img
      style="cursor: pointer;"
      src="https://static.overlay-tech.com/assets/f714d136-8975-4175-bb09-d5a6e114367c.svg"
      @click="activate('left')"
    />
  </n-button-group>
  <n-drawer v-model:show="active" :width="300" :placement="placement">
    <n-drawer-content closable to="/login">
      <div v-if="$root.isSignedIn" @click="to('/logout')">
        <div :class="$style.menu_label">Logout</div>
      </div>
      <div v-else @click="to('/login')">
        <div :class="$style.menu_label">Login／Registration</div>
      </div>
      <n-divider />
      <div @click="to('/products')">
        <div :class="$style.menu_label">Shop</div>
      </div>
      <n-divider />
      <!-- <a href="/#how-to-use">
				<div :class="$style.menu_label">How to Use</div>
			</a>
			<n-divider />
			<a href="/#how-to-buy">
				<div :class="$style.menu_label">How to Buy</div>
			</a>
			<n-divider /> -->
      <div @click="to('/style-guide')">
        <div :class="$style.menu_label">Style Guide</div>
      </div>
    </n-drawer-content>
  </n-drawer>
</template>

<script>
export default {
  name: "VHamburger",
  data() {
    return {
      active: false,
      placement: "right",
    };
  },
  methods: {
    activate(place) {
      this.active = true;
      this.placement = place;
    },
    to(link) {
      this.$router.push(link);
      this.active = false;
      this.placement = "left";
    },
  },
};
</script>
<style lang="scss" module>
.menu_label {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  color: rgba(25, 25, 25, 1);
}
</style>
