<template>
  <div id="product-root">
    <router-view v-if="hasLoadedProducts" />
    <LoadCircle :isVisible="!hasLoadedProducts" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadCircle from "/src/components/ec/common/LoadCircle.vue";
export default {
  name: "RootPage",
  components: {
    LoadCircle,
  },
  data() {
    return {
      hasLoadedProducts: false,
    };
  },
  mounted() {},
  created() {
    this.loadProducts();
  },
  computed: {
    ...mapGetters("auth", { userId: "userId" }),
  },
  methods: {
    ...mapActions("constants", { setConstants: "set" }),
    ...mapActions("products", { setProducts: "set" }),
    async loadProducts() {
      await this.setConstants();
      await this.setProducts({ userId: this.userId });
      this.hasLoadedProducts = true;
    },
  },
};
</script>

<style module></style>
