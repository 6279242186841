<template>
  <div id="cart">
    <FullScreenLoadCircle :isVisible="isDeleting" />
    <h3 style="text-align:center; margin-top:1rem; margin-bottom:1rem;">
      カートに入っている商品：{{ sumAmount }}点
    </h3>
    <LoadCircle :isVisible="!hasLoadedCartItems" />
    <div class="cart-container" v-if="hasLoadedCartItems">
      <CartItem
        v-for="cartItem in cartItems"
        :key="cartItem.product.color.id"
        :cartItem="cartItem"
        @show="clickCartItem(cartItem)"
        @delete="deleteCartItem(cartItem)"
      />
      <CartSum :sum="sumPrice" />
      <Button :buttonText="paymentText" @click="payment" />
      <SecondaryButton
        v-if="!!cartItems.length"
        @click="goShop"
        buttonText="お買い物を続ける"
        style="margin-bottom:1rem; margin-top:1rem;"
      />
      <div :class="$style.payment">
        <p :class="$style.text">
          iPhoneやiPadにあらかじめ登録した、クレジットカード情報や個人情報を利用して、簡単にご注文ができます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadCircle from "/src/components/ec/common/LoadCircle";
import FullScreenLoadCircle from "/src/components/ec/common/FullScreenLoadCircle";
import Button from "/src/components/ec/ui/Button/Button";
import SecondaryButton from "/src/components/ec/ui/Button/SecondaryButton";
import CartItem from "/src/components/ec/cart/CartItem";
import CartSum from "/src/components/ec/cart/CartSum";
export default {
  name: "CartPage",
  components: {
    LoadCircle,
    FullScreenLoadCircle,
    Button,
    SecondaryButton,
    CartItem,
    CartSum,
  },
  data() {
    return {
      hasLoadedCartItems: false,
      isDeleting: false,
    };
  },
  mounted() {},
  created() {
    this.loadCartItems();
  },
  computed: {
    ...mapGetters("carts", { cartItems: "all" }),
    ...mapGetters("auth", { userId: "userId" }),
    sumPrice() {
      let sumPrice = 0;
      this.cartItems.forEach((item) => {
        sumPrice += item.product.color.cost;
      });
      return sumPrice;
    },
    sumAmount() {
      let sumAmount = 0;
      this.cartItems.forEach(() => {
        sumAmount += 1;
      });
      return sumAmount;
    },
    paymentText() {
      return this.cartItems.length ? "決済へ" : "商品一覧へ戻る";
    },
  },
  methods: {
    ...mapActions("carts", {
      loadCarts: "set",
      deleteCart: "delete",
    }),
    async loadCartItems() {
      await this.loadCarts({ userId: this.userId });
      this.hasLoadedCartItems = true;
    },
    clickCartItem(cartItem) {
      this.$router.push({
        name: "ProductShow",
        params: { productId: cartItem.product.id },
        query: { color: cartItem.product.color.color_id },
      });
    },
    async deleteCartItem(cartItem) {
      this.isDeleting = true;
      await this.deleteCart({ userId: this.userId, cartId: cartItem.id });
      this.isDeleting = false;
    },
    payment() {
      if (this.cartItems.length) {
        this.$router.push({ name: "Upload" });
      } else {
        this.$router.push({ name: "ProductIndex" });
      }
    },
    goShop() {
      this.$router.replace("/products");
    },
  },
};
</script>

<style module>
#cart {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.payment {
  width: 60%;
  margin: 1.5rem auto 1.5rem auto;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
</style>
