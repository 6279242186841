import axiosRefresh from "../../api/auth/reflesh";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import axios from "../../plugins/axios";
const state = {
  error: null,
};

const getters = {
  getError: (state) => state.error,
  userId: () => localStorage.getItem("userId"),
  idToken: () => localStorage.getItem("idToken"),
};

const mutations = {
  updateIdToken(idToken) {
    localStorage.setItem("idToken", idToken);
  },
  updateEmail(email) {
    localStorage.setItem("email", email);
  },
  setError(state, payload) {
    state.error = payload;
  },
};

const actions = {
  async signUpAction({ commit, dispatch }, payload) {
    const auth = getAuth();
    let res;
    await createUserWithEmailAndPassword(auth, payload.email, payload.password)
      .then((result) => {
        console.log(result.user);
        dispatch("setAuthData", {
          idToken: result.user.stsTokenManager.accessToken,
          email: result.user.email,
          expiresIn: result.user.stsTokenManager.expirationTime,
          refreshToken: result.user.stsTokenManager.refreshToken,
        });
        res = true;
      })
      .catch((error) => {
        commit("setError", error.message);
        console.log(error.message);
        res = false;
      });
    return res;
  },
  async signInAction({ commit, dispatch }, payload) {
    const auth = getAuth();
    let res;
    await signInWithEmailAndPassword(auth, payload.email, payload.password)
      .then((result) => {
        const user = result.user;
        dispatch("setAuthData", {
          idToken: user.stsTokenManager.accessToken,
          email: user.email,
          expiresIn: user.stsTokenManager.expirationTime,
          refreshToken: user.stsTokenManager.refreshToken,
        });
        res = true;
      })
      .catch((error) => {
        commit("setError", error.message);
        console.log(error);
        res = false;
      });
    return res;
  },
  async googleSignInAction({ commit, dispatch }) {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    let res;
    await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // The signed-in user info.
        dispatch("setAuthData", {
          idToken: result.user.stsTokenManager.accessToken,
          email: result.user.email,
          expiresIn: result.user.stsTokenManager.expirationTime,
          refreshToken: result.user.stsTokenManager.refreshToken,
        });
        res = result.user.email;
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, email, credential);
        commit("setError", errorMessage);
        res = false;
      });
    return res;
  },
  async signOutAction({ commit }) {
    const auth = getAuth();
    await signOut(auth)
      .then(() => {
        console.log("Logout Success");
        commit("carts/DELETE_ALL", {}, { root: true });
        localStorage.removeItem("idToken");
        localStorage.removeItem("email");
        localStorage.removeItem("userId");
        localStorage.removeItem("expiryTimeMs");
        localStorage.removeItem("refreshToken");
      })
      .catch((error) => {
        commit("setError", error.message);
      });
  },
  async autoLogin({ commit, dispatch }) {
    const idToken = localStorage.getItem("idToken");
    console.log("idToken：", idToken);
    if (!idToken) return;
    const now = new Date();
    const expiryTimeMs = localStorage.getItem("expiryTimeMs");
    const isExpired = now.getTime() >= expiryTimeMs;
    const refreshToken = localStorage.getItem("refreshToken");
    if (isExpired) {
      await dispatch("refreshIdToken", refreshToken);
    } else {
      const expiresInMs = expiryTimeMs - now.getTime();
      setTimeout(() => {
        dispatch("refreshIdToken", refreshToken);
      }, expiresInMs);
      commit("updateIdToken", idToken);
    }
  },
  async refreshIdToken({ dispatch }, refreshToken) {
    await axiosRefresh
      .post("/token?key=AIzaSyAAxXYFis-BoKprJXVLikIYnGL8qeEe8aY", {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      })
      .then((response) => {
        dispatch("setAuthData", {
          idToken: response.data.id_token,
          email: localStorage.getItem("email"),
          expiresIn: response.data.expires_in,
          refreshToken: response.data.refresh_token,
        });
      });
  },
  async setAuthData({ dispatch }, authData) {
    const now = new Date();
    const expiryTimeMs = now.getTime() + authData.expiresIn * 1000;
    localStorage.setItem("idToken", authData.idToken);
    localStorage.setItem("email", authData.email);
    localStorage.setItem("expiryTimeMs", expiryTimeMs);
    localStorage.setItem("refreshToken", authData.refreshToken);
    setTimeout(() => {
      dispatch("refreshIdToken", authData.refreshToken);
    }, authData.expiresIn * 1000);
  },
  async getUserInfo() {
    const email = localStorage.getItem("email");
    const guestToken = localStorage.getItem("idToken");
    let res;
    await axios
      .get(
        "/customers/user?mail_address=" + email + "&guest_token=" + guestToken
      )
      .then((response) => {
        console.log(response);
        localStorage.setItem("userId", response.data.user[0].id);
        res = response.data.user[0];
      })
      .catch((error) => {
        console.log(error);
      });

    return res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
