import { render } from "./ProductView.vue?vue&type=template&id=6d73c7c9"
import script from "./ProductView.vue?vue&type=script&lang=js"
export * from "./ProductView.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./ProductView.vue?vue&type=style&index=0&id=6d73c7c9&lang=scss&module=true"
cssModules["$style"] = style0
import style1 from "./ProductView.vue?vue&type=style&index=1&id=6d73c7c9&lang=scss&module=true"
cssModules["$style"] = style1
script.render = render

export default script