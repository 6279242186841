<template>
  <div style="text-align:center; padding: 24px;">
    <h3 style="margin-top:1rem; margin-bottom:1rem;">ご注文が完了しました</h3>
    <h1>Thank You!</h1>
    <div style="margin-top:1rem; margin-bottom:1rem;">{{ user }} 様</div>
    <div>
      ご注文いただき、誠にありがとうございます。<br />
      ご注文内容については、<a href="mailto:info@x-couture.tokyo"
        >info@x-couture.tokyo</a
      >からの<br />メールをお待ちください。
      <!-- ご注文状況は、配信されたご注文確認メールから<br />
			注文履歴（ご注文番号：{{orderNum}}）よりご確認ください。 -->
    </div>
    <Button
      @click="goShop"
      buttonText="お買い物を続ける"
      style="margin-bottom:1rem; margin-top:1rem;"
    />
  </div>
</template>

<script>
import Button from "../../../components/ec/ui/Button/Button.vue";
import axios from "../../../plugins/axios";
export default {
  name: "SuccessPage",
  data() {
    return {
      user: "",
    };
  },
  components: {
    Button,
  },
  methods: {
    goShop() {
      this.$router.replace("/products");
    },
  },
  async mounted() {
    const userId = localStorage.getItem("userId");
    const userInfo = await this.$store.dispatch("auth/getUserInfo");
    console.log(userInfo.last_name);
    if (userInfo.last_name == "guest" || userInfo.first_name == "guest") {
      this.user = userInfo.last_name;
    } else {
      this.user = userInfo.first_name + " " + userInfo.last_name;
    }
    await axios
      .put("/customers/" + userId + "/checkout")
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped></style>
