function ecComps(compName) {
	return require(`../../pages/ec/${compName}`).default;
}

const ecRoutes = [
	{
		path: "/",
		name: "Root",
		component: ecComps("Root"),
		children: [
			{
				path: "",
				name: "Home",
				component: ecComps("home/Home"),
			},
			{
				path: "/login",
				name: "Login",
				component: ecComps("auth/Login"),
			},
			{
				path: "/register",
				name: "Register",
				component: ecComps("auth/Register"),
			},
			{
				path: "/register/success",
				name: "RegSuccess",
				component: ecComps("auth/RegSuccess"),
			},
			{
				path: "/logout",
				name: "Logout",
				component: ecComps("auth/Logout"),
			},
			{
				path: "/style-guide",
				name: "StyleGuide",
				component: ecComps("styleguide/StyleGuide"),
			},
			{
				path: "/products",
				name: "ProductRoot",
				component: ecComps("products/Root"),
				children: [
					{
						path: "",
						name: "ProductIndex",
						component: ecComps("products/Index"),
					},
					{
						path: ":productId",
						name: "ProductShow",
						component: ecComps("products/Show"),
					},
				],
			},
			{
				path: "/cart",
				name: "Cart",
				component: ecComps("cart/Cart"),
				meta: { requiresAuth: true },
			},
			{
				path: "/checkout/success",
				name: "Success",
				component: ecComps("checkout/Success"),
				meta: { requiresAuth: true },
			},
			{
				path: "/checkout/cancel",
				name: "Cancel",
				component: ecComps("checkout/Cancel"),
				meta: { requiresAuth: true },
			},
			{
				path: "/upload",
				name: "Upload",
				component: ecComps("upload/Upload"),
				meta: { requiresAuth: true },
			},
			{
				path: "/:otherPath(.*)",
				name: "404",
				component: ecComps("not-found/404"),
			},
		],
	},
];

ecRoutes;
export default ecRoutes;
