<template>
	<div :class="$style.container">
		<div :class="$style.flex">
			<img
				:class="$style.cartItemImage"
				:src="uploadItem.cartItem.product.color.images[0].url"
				@click="$emit('show')"
			/>
			<div :class="$style.margin" @click="$emit('show')">
				<p :class="$style.text">
					{{ uploadItem.cartItem.product.color.name }}
				</p>
				<p :class="$style.text">
					カラー：{{ uploadItem.cartItem.product.color.color_id }}
				</p>
				<p :class="$style.text">
					￥ {{ uploadItem.cartItem.product.color.cost }} 税込
				</p>
			</div>
		</div>
		<div :class="$style.flex">
			<div :class="$style.uploadItemsContainer">
				<div :class="$style.backgroundBar" />
				<div :class="$style.uploadItem" v-for="i in [1,2,3]" :key="i">
					<input
						type="file"
						accept="image/*"
						:id="`${uploadItem.cartItem.id}image${i}`"
						:class="$style.inputFile"
						@change="selectedFile($event, i)"
					/>
					<label :for="`${uploadItem.cartItem.id}image${i}`" :class="$style.imageUpload">
						<img :src="uploadItem[`image${i}URL`]" v-if="uploadItem[`image${i}URL`]" :class="$style.uploadItemImage">
						<div :class="$style.addCircle" v-else>
							<div :class="$style.horizontalCross" />
							<div :class="$style.verticalCross" />
						</div>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import Stepper from "/src/components/ec/ui/Stepper";
export default {
	props: {
		uploadItem: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {}
	},
	components: {
		// Stepper,
	},
	computed: {
	},
	methods: {
		selectedFile(e, i) {
			e.preventDefault();
			let files = e.target.files;
			this.$emit('upload', this.uploadItem.cartItem.id, i, files[0]);
		},
	}
};
</script>

<style lang="scss" module>
@import "../../../stylesheet";
.container {
	.flex {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}
	.cartItemImage {
		margin: 1rem auto 1rem auto;
		width: 100px;
		height: 120px;
	}
	.margin {
		margin: 1rem auto 1rem auto;
	}
	.text {
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.05em;
		--tw-text-opacity: 1;
		color: rgba(55, 65, 81, var(--tw-text-opacity));
		margin-bottom: 0.25rem;
	}
	.uploadItemsContainer {
		width: 300px;
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		position: relative;
		.backgroundBar {
			width: 100%;
			height: 20px;
			position: absolute;
			top: 40px;
			z-index: -1;
			background: linear-gradient(
				108deg,
				$medium-slate-blue 0%,
				$medium-turquoise 100%
			);
		}
	}
	.inputFile {
		display: none;
	}
	.imageUpload {
		display: block;
		width: 80px;
		height: 80px;
		background-color: gray;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}
	.uploadItemImage {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	.addCircle {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		position: relative;
		background-color: white;
		.horizontalCross {
			width: 14px;
			height: 4px;
			border-radius: 3px;
			background-color: black;
			position: absolute;
			top: 18px;
			left: 13px;
		}
		.verticalCross {
			width: 4px;
			height: 14px;
			border-radius: 3px;
			background-color: black;
			position: absolute;
			top: 13px;
			left: 18px;
		}
	}
}
</style>
