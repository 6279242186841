<template>
  <footer>
    <div :class="$style.img">
      <img
        :class="[$style.round, $style.mr]"
        src="../../../assets/images/facebook.svg"
      />
      <a href="https://www.instagram.com/x_couture.official/">
        <img
          :class="[$style.round, $style.mr]"
          src="../../../assets/images/instagram.svg"
        />
      </a>
      <img :class="$style.round" src="../../../assets/images/youtube.svg" />
    </div>
    <div>
      <p :class="$style.copyright">
        Copyright © 2021, X-couture (JP)
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "V-Footer",
  components: {},
};
</script>

<style module>
.img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.round {
  border-radius: 9999px;
}
.copyright {
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
}
.mr {
  margin-right: 2rem;
}
</style>
