<template>
	<div :class="$style.card">
		<img :src="color.images[0].url" alt="" :class="$style.img" />
		<div :class="$style.product_color">
			<div
				:class="$style.color"
				v-bind:style="'background-color:#' + color.code + ';'"
			></div>
		</div>
		<p :class="$style.name">{{ color.name }}</p>
		<p :class="$style.name">¥{{ color.cost }}</p>
	</div>
</template>

<script>
export default {
	name: "ProductCard",
	props: {
		product: {
			type: Object,
			default: () => {},
		},
		color: {
			type: Object,
			default: () => {},
		},
	},
};
</script>

<style lang="scss" module>
@import "../../../stylesheet";
.card {
	background-color: $grayscale-off-white;
	border-radius: 16px;
	padding: 16px 28px;
	box-shadow: 0 32px 64px 0 $transparent-black-3;
	display: flex;
	margin: auto auto 3vw auto;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	user-select: none;
	&:hover {
		opacity: 0.8;
	}
}
.img {
	width: 100px;
	height: 120px;
	margin-bottom: 12px;
}
.product_color {
	margin-bottom: 16px;
	display: flex;
}
.color {
	width: 10px;
	height: 10px;
	margin-right: 5px;
}
.name {
	font-size: 0.75rem;
	color: rgba(66, 66, 66, 1);
	letter-spacing: 0.75px;
	// @include mobile-text-japanese-x-small;
	&:not(:last-of-type) {
		margin-bottom: 4px;
	}
}
</style>
