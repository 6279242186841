<template>
	<div id="style-guide">
		<div :class="$style.container">
			<p :class="$style.font">
				Style Guide
			</p>
			<ul :class="$style.flex">
				<StyleGuideCard
					description="光が自然である"
					:styleImage="success1"
					:vector="vectorSuccess"
					:iconColor="successColor"
				/>
				<StyleGuideCard
					description="光が強すぎる"
					:styleImage="error1"
					:vector="vectorError"
					:iconColor="errorColor"
				/>
				<StyleGuideCard
					description="身体にフィットした服である"
					:styleImage="success2"
					:vector="vectorSuccess"
					:iconColor="successColor"
				/>
				<StyleGuideCard
					description="影が濃いまたは強すぎる"
					:styleImage="error2"
					:vector="vectorError"
					:iconColor="errorColor"
				/>
				<StyleGuideCard
					description="髪が体や衣装に被っていない"
					:styleImage="success3"
					:vector="vectorSuccess"
					:iconColor="successColor"
				/>
				<StyleGuideCard
					description="髪が体や衣装に多く被っている"
					:styleImage="error3"
					:vector="vectorError"
					:iconColor="errorColor"
				/>
				<StyleGuideCard
					description="顔や身体の光が均一である"
					:styleImage="success4"
					:vector="vectorSuccess"
					:iconColor="successColor"
				/>
				<StyleGuideCard
					description="顔や身体、画像が暗すぎる"
					:styleImage="error4"
					:vector="vectorError"
					:iconColor="errorColor"
				/>
			</ul>
			<p :class="$style.font">
				Point
			</p>
			<ul :class="$style.ul">
				<li :class="$style.li">
					写真のサイズに関して制限はありません。
				</li>
				<li :class="$style.li">
					大きく高画質な写真の方がより良いGI画像を作ることが可能なため、基本的にはオリジナルのサイズで送ることをお勧めします。
				</li>
				<li :class="$style.li">
					複数の写真を送っていただき、弊社の方で最適な画像を選択いたします。
				</li>
				<li :class="$style.li">
					同一の注文から複数の商品を組み合わせて写真に組み込むことも可能です。（その際はコメント欄にてご記入ください）
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import StyleGuideCard from "../../../components/ec/ui/StyleGuideCard";

export default {
	name: "StyleGuide",
	components: {
		StyleGuideCard,
	},
	data() {
		return {
			success1: require("../../../assets/images/style-guide/Success1.png"),
			success2: require("../../../assets/images/style-guide/Success2.png"),
			success3: require("../../../assets/images/style-guide/Success3.png"),
			success4: require("../../../assets/images/style-guide/Success4.png"),
			error1: require("../../../assets/images/style-guide/Error1.png"),
			error2: require("../../../assets/images/style-guide/Error2.png"),
			error3: require("../../../assets/images/style-guide/Error3.png"),
			error4: require("../../../assets/images/style-guide/Error4.png"),
			vectorSuccess:
				"https://static.overlay-tech.com/assets/0b679309-c77c-4f5a-a126-22e86fdf2960.svg",
			vectorError:
				"https://static.overlay-tech.com/assets/fa894e13-be9f-40ec-87e2-2a4c040e2b90.svg",
			successColor: "background-color: rgba(0, 186, 136, 1);",
			errorColor:
				"background-color:rgba(237, 46, 126, 1); padding:6px 6px 5px 6px;",
		};
	},
	mounted() {},
	methods: {},
};
</script>

<style module>
.container {
	position: relative;
	/* --tw-bg-opacity: 1; */
	/* background-color: rgba(249, 250, 251, var(--tw-bg-opacity)); */
	padding-bottom: 1.5rem;
}
.font {
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 700;
	letter-spacing: 0.05em;
	line-height: 2;
	text-align: center;
	--tw-text-opacity: 1;
	color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.flex {
	display: flex;
	flex-wrap: wrap;
	padding: initial;
}
.ul {
	font-size: 0.75rem;
	line-height: 1rem;
	letter-spacing: 0.025em;
	--tw-text-opacity: 1;
	color: rgba(55, 65, 81, var(--tw-text-opacity));
	width: auto;
}
.li {
	list-style-type: disc;
	margin-left: 2rem;
	margin-right: 2rem;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}
</style>
