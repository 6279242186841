<template>
  <div :class="$style.bg_gray_50">
    <div :class="$style.instagram_feed_image">
      <div :class="$style.dress01">
        <img src="../../../assets/images/home/Dress01.png" />
      </div>
      <div :class="$style.dress02">
        <img src="../../../assets/images/home/Dress02.png" />
      </div>
    </div>
    <div :class="$style.instagram_feed_image">
      <div>
        <div>
          <div :class="$style.dress03">
            <img src="../../../assets/images/home/Dress03.png" />
          </div>
          <div :class="$style.dress05">
            <img src="../../../assets/images/home/Dress05.png" />
          </div>
        </div>
      </div>
      <div :class="$style.dress04">
        <img src="../../../assets/images/home/Dress04.png" />
      </div>
    </div>
    <div :class="$style.instagram_feed_image">
      <div :class="$style.dress07">
        <img src="../../../assets/images/home/Dress07.png" />
      </div>
      <div>
        <div :class="$style.dress06">
          <img src="../../../assets/images/home/Dress06.png" />
        </div>
        <div :class="$style.dress08">
          <img src="../../../assets/images/home/Dress08.png" />
        </div>
      </div>
    </div>
    <div :class="$style.title_position1">
      <p :class="$style.about_xcouture">
        About X-couture
      </p>
      <p :class="$style.about_xcouture_title">
        国内初
        <br />
        デジタルファッションの
        <br />
        プラットフォーム
      </p>
    </div>
    <div :class="$style.title_position2">
      <p :class="$style.about_xcouture">
        About Digital Dress
      </p>
      <div :class="$style.title_margin">
        <p :class="$style.about_xcouture_title">
          デジタルファッションとは
          <br />
          バーチャル空間上で
          <br />
          使用可能な衣服
        </p>
      </div>
    </div>
    <div>
      <router-link to="/products">
        <div :class="$style.shop_button">
          <Button buttonText="Shop Now" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from "/src/components/ec/ui/Button/Button";

export default {
  name: "InstagramFeed",
  components: {
    Button,
  },
};
</script>

<style lang="scss" module>
@import "../../../stylesheet";
.bg_gray_50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.instagram_feed_image {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.dress01 {
}
.dress02 {
}
.dress03 {
  padding-bottom: 2rem;
}
.dress04 {
  padding-bottom: 2rem;
}
.dress05 {
}
.dress06 {
  padding-bottom: 2rem;
}
.dress07 {
  padding-top: 3rem;
}
.dress08 {
  padding-left: 1.5rem;
}
.about_xcouture {
  width: 66.666667%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.25;
}
.title_margin {
  margin-bottom: 10px;
}
.title_position1 {
  position: absolute;
  left: 51px;
  top: 200px;
}
.title_position2 {
  position: absolute;
  left: 40px;
  top: 691px;
  text-align: right;
}
.about_xcouture_title {
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.shop_now {
  font-size: 0.875rem;
  line-height: 1.25rem;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.25;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.shop_button {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(100%);
  z-index: 10;
}
</style>
