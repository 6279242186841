<template>
  <div :class="$style.navigation">
    <Hamburger :class="$style.hamburger" />
    <router-link to="/" :class="$style.routerLink">
      <div :class="$style.x_couture">X-couture</div>
    </router-link>
    <!-- <router-link to="/login" v-if="!isUserAuth">
			<div style="font-size: 1rem; padding: 0.1rem 0 0 1rem;">
				ログイン
			</div>
		</router-link> -->
    <!-- <router-link to="/logout" v-if="isUserAuth">
			<div
				@click="signOut()"
				style="font-size: 0.9rem; padding: 0.1rem 0 0 1rem;"
			>
				ログアウト
			</div>
		</router-link> -->
    <router-link to="/cart">
      <div :class="$style.shopping_cart_two">
        <Cart :class="$style.shopping_cart" />
      </div>
    </router-link>
  </div>
</template>

<script>
import Hamburger from "../icons/Hamburger.vue";
import Cart from "../icons/Cart.vue";

export default {
  name: "V-Header",
  components: {
    Hamburger,
    Cart,
  },
  // computed: {
  // 	getUser: () => {
  // 		const email = localStorage.getItem("email");
  // 		return email;
  // 	},
  // 	isUserAuth: () => {
  // 		const idToken = localStorage.getItem("idToken");
  // 		return !!idToken;
  // 	},
  // },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOutAction");
      console.log("Logout Success");
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../stylesheet";

.navigation {
  background-size: 100% 100%;
  background-position: center;
  background-image: url("https://static.overlay-tech.com/assets/c53443bb-4768-44ce-8d96-2b65e341843b.png");
  padding: 5px 24px 4px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  // gap: 20%;
}
.hamburger {
  width: 36px;
  height: 36px;
  margin-right: 24px;
}
.routerLink {
  text-decoration: none;
}
.x_couture {
  font-size: 24px;
  color: rgba(25, 25, 25, 1);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  letter-spacing: 0.8px;
  @include mobile-display-english-small;
  white-space: nowrap;
  padding-left: 48px;
  padding-right: 48px;
}
.search {
  padding: 3vw 2.5vw 2.5vw 2.5vw;
  display: flex;
  align-items: center;
}
.shopping_cart-two {
  padding: 3vw 2.5vw 2.5vw 2.5vw;
  display: flex;
  align-items: center;
  margin-left: 24px;
}
</style>
