import { createApp } from "vue";
import App from "./App.vue";
import "./plugins/firebase";
import naive from "naive-ui";

const app = createApp(App);

// ------store------
import store from "./store";

app.use(store);
// ------/store------

// ------router------
import router from "./router";

app.use(router);
// ------/router------

app.mixin({
	data() {
		return {
			isSignedIn: false,
		};
	},
});

app.use(naive);

app.mount("#app");
