import axios from "axios";

const axios_instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});
axios_instance.interceptors.request.use((config) => {
  config.headers = {
    Accept: "application/json",
    "x-api-key": process.env.VUE_APP_API_KEY,
  };
  return config;
});

export default axios_instance;
