<template>
	<div>
		<!-- <ResetPassword /> -->
        <label for="email">Email：</label>
		<input id="email" type="email" v-model="email" />
        <!-- <button @click.prevent="validate()">送信</button> -->
        <button @click.prevent="sendEmail()">送信</button>
	</div>
</template>

<script>
// import ResetPassword from "../../../components/ec/auth/ResetPassword.vue";

export default {
	name: "ResetPassword",
	// components: { ResetPassword },
        data() {
        return {
            email: null
        };
    },
    methods: {
        async sendEmail(){
            await this.$store.dispatch("auth/sendPassResetEmail", {
                email: this.email
            }).then(() => {
                console.log("成功")
            }).catch(()=>{
                console.log("失敗")
            })
            this.email = null
        },
        validate() {
			// email validation
			if (!this.email) {
				this.validationErrors.push("<strong>E-mail</strong> cannot be empty.");
			}
			if (/.+@.+/.test(this.email) != true) {
				this.validationErrors.push("<strong>E-mail</strong> must be valid.");
			}
			// when valid then sign in
			if (this.validationErrors.length <= 0) {
				// this.register();
				// this.sendEmail();
                console.log("成功")
			}
		},
    },
};
</script>
